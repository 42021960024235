import React, { useEffect, useState } from "react";
import { WorkshopItem } from "../WorkshopItem";
import { useStep } from "../../hooks/context/StepContext";
import { collection, getDocs } from "firebase/firestore";
import firebase from "../../firebase/firebase";

export const WorkshopList = () => {
  const [selectedWorkshopDuration, setSelectedWorkshopDuration] = useState(null);
  const [isSingleSession, setIsSingleSession] = useState(false); // New state for single session
  const { getWorkshop } = useStep();
  const workshopName = getWorkshop();

  const handleCalculateCheapestDay = () => {
    if (selectedWorkshopDuration) {
      const next = new CustomEvent('nextStep');
      window.dispatchEvent(next);
    }
  };

  useEffect(() => {
    const storedWorkshopDuration = JSON.parse(localStorage.getItem("selectedWorkshopDuration"));
    if (storedWorkshopDuration) {
      setSelectedWorkshopDuration(storedWorkshopDuration);
    }
  }, []);

  useEffect(() => {
    if (selectedWorkshopDuration) {
      localStorage.setItem("selectedWorkshopDuration", JSON.stringify(selectedWorkshopDuration));
      window.dispatchEvent(new Event('local-storage-changed'));
    }
  }, [selectedWorkshopDuration]);

  const handleWorkshopSelect = (workshop) => {
    setSelectedWorkshopDuration(workshop.duration);
  };

  // Fetching SingleSession workshops from Firestore
  useEffect(() => {
    const fetchSingleSession = async () => {
      try {
        const querySnapshot = await getDocs(collection(firebase.db, 'single_session'));
        const singleSessionWorkshops = querySnapshot.docs.map((doc) => doc.id.replace(/_/g, ' ')); // Transform IDs to workshop names with spaces
        
        // Check if the current workshop is in the SingleSession collection
        if (singleSessionWorkshops.includes(workshopName)) {
          setIsSingleSession(true);
        }
      } catch (error) {
        console.error("Error fetching single session workshops:", error);
      }
    };

    fetchSingleSession();
  }, [workshopName]);

  // Define available workshop options
  const workshops = [
    { name: `${workshopName} Full Day`, duration: "Full", desc: "Full day workshop" },
    { name: `${workshopName} Half Day`, duration: "Half", desc: "Half day workshop" }
  ];

  // If the workshop is a single session, add the Single Session option
  if (isSingleSession) {
    workshops.push({ name: `${workshopName} Single Session`, duration: "Single Session", desc: "Single session workshop" });
  }

  return (
    <div className={`workshop-list ${isSingleSession ? 'workshop-list--single-session' : ''}`}>
    {workshops.map((workshop, index) => (
      <WorkshopItem
        key={index}
        name={workshop.name}
        desc={workshop.desc}
        isSelected={workshop.duration === selectedWorkshopDuration}
        onWorkshopSelect={() => handleWorkshopSelect(workshop)}
      />
    ))}
    <button className="workshop-list__button" onClick={handleCalculateCheapestDay}>
      Show the cheapest workshop
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M2.51842e-06 11.0101L2.69504e-06 8.9899L16.1212 8.9899L10.5657 3.43434L12 2L20 10L12 18L10.5657 16.5657L16.1212 11.0101L2.51842e-06 11.0101Z"
          fill="#0059FC"
        />
      </svg>
    </button>
  </div>
  );
};
