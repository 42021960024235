import React, { createContext, useState,  } from "react";
import { useNavigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import {db} from "../../firebase/firebase";
import {useStep} from "./StepContext";
import ReactGA from 'react-ga4';



export const FormContext = createContext();

export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        schoolName: '',
        schoolPostcode: '',
        agreeToTerms: false
    });

    const navigate = useNavigate(); 

    const {successStep} = useStep();

    

    const handleFormSubmit  = async (event) => {
      event.preventDefault();
      //console.log('Form submitted, email:', formData.email);
      



      //validate email
    //   if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
    //     alert('Please enter a valid email address.');
    //     return;
    // }

    localStorage.setItem('formData', JSON.stringify(formData));


    const savedFormData = JSON.parse(localStorage.getItem('formData'));
        const selectedWorkshopName = localStorage.getItem('selectedWorkshopName');
        const selectedDate = localStorage.getItem('selectedDate');
        const selectedOptionPrice = localStorage.getItem('selectedOptionPrice');
        const calendarPrice = localStorage.getItem('calendarPrice');
        const selectedPrice = localStorage.getItem('selectedPrice');
        const damageWaiver = JSON.parse(localStorage.getItem('damageWaiver'));
        const duration = localStorage.getItem("selectedWorkshopDuration");

        const DiscountedPriceChosen = calendarPrice > selectedPrice ? 
        "Yes. In line with our terms and conditions the option you have chosen is non refundable." : 
        "No";
    


    if (formData.email) {
        const bookingData = {
            ...savedFormData,
            chosenWorkshop: selectedWorkshopName,
            chosenWorkshopDuration: duration,
            email: formData.email,
            selectedDate: selectedDate,
            selectedOption: selectedOptionPrice,
            calendarPrice: calendarPrice,
            selectedPrice: selectedPrice,
            DiscountedPriceChosen: DiscountedPriceChosen,
            isDamageWaiverSelected: damageWaiver?.isSelected,
            createdAt: serverTimestamp()
        };

        try {
            await addDoc(collection(db, 'au_bookings'), bookingData);
            console.log('Booking saved successfully!');

            // Send conversion event to Google Analytics
            ReactGA.gtag('event', 'conversion', {
                send_to: process.env.REACT_APP_AW_CONVERSION_ID, 
                value: selectedPrice, 
                currency: 'GBP',
            });


             successStep();
        } catch (error) {
            console.error('Error saving booking:', error);
            alert('Failed to save booking. Please try again.');
        }
    } else {
       alert('No saved form data matches the email address.');
    } 

    

}



  
    return (
        <FormContext.Provider value={{ formData, setFormData, handleFormSubmit}}>
            {children}
        </FormContext.Provider>
    );
};




// Generate a random password
    // const password  = process.env.REACT_APP_PASSWORD;
    //console.log('Random password:', password );
    //console.log('Form email:', formData.email);


    // firebase.createUserWithEmailAndPassword(firebase.auth,formData.email, password)
    // .then(() => {
    //     // Email verification sent
    //     firebase.sendEmailVerification(firebase.auth.currentUser)
    //         .then(() => {
    //             alert("A verification link has been sent to your email address. Please check your inbox.");
    //             navigate('/verification'); // Navigate after sending verification
    //         })
    //         .catch((error) => {
    //             console.error("Error sending verification email: ", error);
    //             alert("An error occurred while sending the verification email. Please try again.");
    //         });
    // })
    // .catch((error) => {
    //     if (error.code === 'auth/email-already-in-use') {
    //         console.log('Email already in use, attempting to sign in and send verification.');
    //         // If email already in use, try to sign in and send verification email
    //         firebase.signInWithEmailAndPassword(firebase.auth,formData.email, password)
    //             .then((userCredential) => {
    //                 firebase.sendEmailVerification(userCredential.user)
    //                     .then(() => {
    //                         alert("A verification link has been sent to your email address. Please check your inbox.");
    //                         navigate('/verification'); // Navigate after handling existing user
    //                     })
    //                     .catch((error) => {
    //                         console.error("Error re-sending verification email: ", error);
    //                         alert("An error occurred while re-sending the verification email. Please try again.");
    //                     });
    //             })
    //             .catch((error) => {
    //                 console.error("Error signing in: ", error);
    //                 alert("An error occurred while signing in. Please check your credentials and try again.");
    //             });
    //     } else {
    //         console.error("Error while creating user: ", error);
    //         alert("An error occurred. Please try again.");
    //     }
    // });
    // };


    // const handleResendLink = (email) => {
    //     // Get the current user based on the email
    //     const user = firebase.auth.currentUser;
    
    //     if (user && user.email === email) {
    //         // User found and email matches, send verification email
    //         firebase.sendEmailVerification(user)
    //         .then(() => {
    //             alert("A verification link has been resent to your email address. Please check your inbox.");
    //         })
    //         .catch((error) => {
    //             console.error("Error sending verification link: ", error);
    //             alert("An error occurred while sending the verification link. Please try again.");
    //         });
    //     } else {
    //         alert("User not found or email does not match. Please make sure you are signed in and have entered the correct email address.");
    //     }
    // };
    