import React from "react";



export const SubTotal = ({ amount }) => {
  return (

    
    <div className="subtotal">
      <span className="subtotal__currency">$</span>
      <span className="subtotal__amount">{amount}</span>
      <span className="subtotal__label">Sub Total</span>
    </div>
  );
};
