import React from "react";
import { useContext } from "react";
import { FormContext } from "../hooks/context/FormContext ";

export const Summary = () => {

    //context states from prev steps

    const savedWorkshopName = localStorage.getItem("selectedWorkshopName");
    const savedDate = JSON.parse(localStorage.getItem("selectedDate"));
    const price = JSON.parse(localStorage.getItem("selectedOptionPrice"));


    const { formData } = useContext(FormContext);


    return (
            <div className="booking-details">
            <h2 className="booking-details__heading">Booking Summary</h2>
            <section className="booking-details__summary">
            <div className="booking-details__summary--item">
                <p className="booking-details__summary--description">{savedWorkshopName}</p>
                <p className="booking-details__summary--price">${price}</p>
            </div>
            <div className="booking-details__date">
                <p>{savedDate}</p>
            </div>
            <div className="booking-details__summary--item">
                <p className="booking-details__summary--label">Total</p>
                <p className="booking-details__summary--amount">${price}</p>
            </div>
            </section>
            
            <h2 className="booking-details__heading">Personal Details</h2>
            <section className="booking-details__personal">
            <div className="booking-details__personal--item">
                <p>{formData.fullName}</p>
            </div>
            <div className="booking-details__personal--item">
                <p>{formData.email}</p>
            </div>
            <div className="booking-details__personal--item">
                <p>{formData.phoneNumber}</p>
            </div>
            <div className="booking-details__personal--item">
                <p>{formData.schoolName}</p>
            </div>
            <div className="booking-details__personal--item">
                <p>{formData.schoolPostcode}</p>
            </div>
            </section>
        </div>

    );
}
