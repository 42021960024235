import React from "react";

export const BookOption = ({ price, discount, isSelected, onOptionChange,  isDamageWaiverSelected, onDamageWaiverChange, priceText  }) => {

    const selectedClass = isSelected ? "book-option selected" : "book-option";
  return (


<label className="book-option__label">
    <div className={selectedClass}>
        <div className="book-option__heading">
            <input type="radio" className="book-option__heading--input" name="bookingOption" 
                onChange={onOptionChange} 
                checked={isSelected}/>
            <p className="book-option__heading--text">{priceText}
                <span className="book-option__heading--price">${price}</span>
            </p>
        </div>

        {discount && (
            <div className="book-option__discount">
                <p>${discount} discount</p>
            </div>
        )}

        <div className="book-option__description">
           
            <p className="book-option__description--desc">
            {discount
              ? "Our discounted option is non refundable although date changes may be permissible if available."
              : "Choose this option for free cancellation within the next 28 days. Cancellation after this point is subject to our standards T&Cs. "}
            {!discount && (
              <a href="https://www.educationgroup.co.uk/workshop-terms" target="_blank" rel="noopener noreferrer">
                Terms & Conditions
              </a>
            )}
            </p>
            <label className="book-option__description--tooltip">
                <input type="checkbox"
                       className="book-option__description--tooltip--checkbox"
                       onChange={() => onDamageWaiverChange(price)}
                       checked={isDamageWaiverSelected} />
                <p>Add damage waiver</p>
                <span className="book-option__description--tooltip--text">
                Remove the risk of accidental damage to our VR equipment by adding our Damage Waiver service. 
                For a cost of 7% of the workshop fee, schools selecting this option shall have no liability 
                under <a href="https://www.educationgroup.co.uk/workshop-terms" target="_blank" rel="noopener noreferrer" className="book-option__description--tooltip--text--link">clause 3.5 of our terms and conditions for accidental damage.</a>
                    </span>
            </label>
        </div>
    </div>
</label>
  );
};
